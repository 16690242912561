import React, { Component } from "react";
import ProjectList from "./projectList";
import App from "./App";
import Login from "./Login";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

class CheckLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedIn: null,
      multiProj: [],
    };
    this.docData = null;
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      var multiProj = [];
      if (user) {
        const projQuery = firebase
          .firestore()
          .collection("Users")
          .doc(user.uid);

        projQuery
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.docData = doc.data();
              multiProj = doc.data().path;
              return;
            } else {
              console.log("error");
            }
          })
          .then(() => {
            this.setState({
              multiProj: multiProj,
              signedIn: true,
            });
          });
      } else {
        this.setState({
          signedIn: false,
        });
      }
    });
  }

  render() {
    return (
      <div>
        {this.state.signedIn ? (
          this.state.multiProj.length === 1 ? (
            <App userData={this.docData} selectProj={this.state.multiProj[0]} />
          ) : (
            <ProjectList
              projectList={this.state.multiProj}
              userData={this.docData}
            />
          )
        ) : this.state.signedIn == null ? null : (
          <Login />
        )}
      </div>
    );
  }
}

export default CheckLogin;
