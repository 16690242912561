import React, { Component } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import styled from "styled-components";
import { Form, FormField, Button, Grommet, TextInput } from "grommet";
import "./login.css";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      popUpStatus: "false",
      resetEmail: "",
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.resetEmail = this.resetEmail.bind(this);
    this.handleResetSubmit = this.handleResetSubmit.bind(this);
    this.MyStyledButton = styled(Button)`
      display: block;
      margin-left: auto;
      margin-right: 0px;
      margin-top: 40px;
      font-weight: 500;
      background-color: rgb(0, 0, 0);
    `;
    this.customTheme = {
      textInput: {
        extend: () => `
        @media only screen and (min-width: 650px) {
          font-size: 20px;
          font-weight: 600;
         }


        @media only screen and (max-width: 650px) {
          font-size: 18px;
          font-weight: 600;
         }

         @media only screen and (max-width: 550px) {
          font-size: 16px;
          font-weight: 600;
         }
         @media only screen and (max-width: 450px) {
          font-size: 14px;
          font-weight: 600;
         }

         `,
      },
      global: {
        colors: {
          border: "rgb(0,0,0)",
        },
        input: {},
        font: {},
      },
      button: {
        border: {
          radius: "4px",
          color: "black",
        },
        padding: {
          vertical: "10px",
        },
        primary: {},
        backgroundColor: "rgb(0,0,0)",
      },
      FormField: {
        border: {
          color: "rgb(0,0,0)",
        },
      },
    };
  }
  componentDidMount() {}
  handleChange(e) {
    e.preventDefault();
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
    });
  }
  resetEmail(event) {
    this.setState({ resetEmail: event.target.value });
  }
  handleSubmit(e) {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.username, this.state.password)
      .catch((error) => {
        var errorMessage = error.message;
        this.setState({
          error: errorMessage,
        });
        console.log(error);
      });
  }

  resetPassword() {
    this.setState({
      popUpStatus: "true",
    });
  }

  handleResetSubmit(event) {
    event.preventDefault();
    var emailAddress = this.state.resetEmail.toString().trim();
    var context = this;
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
        alert("Email Sent! Please Check Your Email");
        context.setState({
          popUpStatus: "false",
        });
      })
      .catch(function (error) {
        alert("Please try again");
      });
  }

  closePopup = () => {
    this.setState({
      popUpStatus: "false",
    });
  };
  render() {
    return (
      <div className="wrapper">
        <div className="bg1"></div>
        <div className="bg2"></div>
        <div className="bg3"></div>
        <div className="loginWrapWrap">
          <div className="llogo">
            <img src="https://lfe-resources.s3.ap-south-1.amazonaws.com/assets/lfe-logo.jpeg" />
          </div>
          <div className="loginWrap">
            <Grommet theme={this.customTheme}>
              <Form>
                <FormField className="userID">
                  <TextInput
                    placeholder="User ID"
                    className="userID1"
                    onInput={this.handleChange}
                    name="username"
                    required={true}
                  />
                </FormField>
                <FormField className="passWord" error={this.state.error}>
                  <TextInput
                    type="password"
                    className="passWord1"
                    placeholder="Password"
                    onInput={this.handleChange}
                    name="password"
                    required={true}
                  />
                </FormField>
                <div>
                  <div
                    className="reset forgetSubmit"
                    onClick={this.resetPassword}
                  >
                    Forget Password?
                  </div>
                  <this.MyStyledButton
                    type="submit"
                    onClick={this.handleSubmit}
                    className="Submit forgetSubmit"
                    primary
                    label="Submit"
                  />
                </div>
              </Form>
            </Grommet>
          </div>{" "}
        </div>{" "}
        <div className={`overLay ${this.state.popUpStatus}`} />{" "}
        <div className={`popUp1 ${this.state.popUpStatus}`}>
          {" "}
          <div className={`close ${this.state.popUpStatus}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              onClick={this.closePopup}
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
          <div className="resetBodyWrap">
            <div className="resetBody">
              <div className="resetTitle">Reset Password</div>
              <div className="resettext">
                Please enter the registered email ID, password reset link will
                be sent to your inbox
              </div>
              <div className="popup1Wrap">
                {" "}
                <Grommet theme={this.customTheme}>
                  <Form>
                    <FormField className="userID">
                      <TextInput
                        placeholder="UserId"
                        type="email"
                        className="resetInput"
                        onInput={this.resetEmail}
                        name="Email Id"
                        required={true}
                      />
                    </FormField>

                    <this.MyStyledButton
                      type="submit"
                      onClick={this.handleResetSubmit}
                      className="sendMail"
                      primary
                      label="Send Mail"
                    />
                  </Form>
                </Grommet>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
