import React, { Component } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import styled from "styled-components";
import { Button, Grommet } from "grommet";
import "./projectlist.css";
import App from "./App";

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectProj: null,
    };

    this.onClickProj = this.onClickProj.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.MyStyledButton = styled(Button)`
      display: block;
      margin-left: auto;
      margin-right: 0px;
      background-color: #e65660;
      border: none;
      font-weight: 500;
    `;
    this.customTheme = {
      textInput: {
        extend: () => `
        @media only screen and (min-width: 650px) {
          font-size: 20px;
          font-weight: 600;
         }


        @media only screen and (max-width: 650px) {
          font-size: 18px;
          font-weight: 600;
         }

         @media only screen and (max-width: 550px) {
          font-size: 16px;
          font-weight: 600;
         }
         @media only screen and (max-width: 450px) {
          font-size: 14px;
          font-weight: 600;
         }

         `,
      },
      global: {
        colors: {
          border: "rgb(0,0,0)",
        },
        input: {},
        font: {},
      },
      button: {
        border: {
          radius: "4px",
        },
        padding: {
          vertical: "10px",
        },
        primary: {},
      },
      FormField: {
        border: {
          color: "rgb(0,0,0)",
        },
      },
    };
  }
  componentDidMount() {
    // console.log(this.props.projectList)
  }
  onClickProj(projs) {
    this.setState({
      selectProj: projs,
    });
  }
  handleLogout = () => {
    var cache = this;
    firebase
      .auth()
      .signOut()
      .then(function () {
        cache.setState({
          signedIn: false,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  render() {
    return (
      <div>
        {this.state.selectProj === null ? (
          <div className="wrapper1">
            {" "}
            <div className="llogo1">
              <img src="https://lfe-resources.s3.ap-south-1.amazonaws.com/assets/lfe-logo.jpeg" />
            </div>
            <Grommet theme={this.customTheme}>
              <this.MyStyledButton
                type="submit"
                onClick={this.handleLogout}
                className="Logout"
                primary
                label="Logout"
              />
            </Grommet>
            <div className="loginWrapWrap1">
              <div className="header" style={{ color: "#5E97FF" }}>
                All Projects
              </div>
              <div className="loginWrap1">
                {this.props.projectList.map((projs) => {
                  return (
                    <div
                      className="box"
                      key={projs}
                      onClick={() => this.onClickProj(projs)}
                    >
                      <div
                        className="innerBox"
                        style={{
                          color: "#5E97FF",
                        }}
                      >
                        {projs}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          //   FF93C3C
          <App
            selectProj={this.state.selectProj}
            userData={this.props.userData}
          />
        )}
      </div>
    );
  }
}

export default ProjectList;
